import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_news.scss";
import ogImage from "../images/OG_Image.png";

const News = () => {
  const stories = [
    {
      publication: "ISS Insights",
      date: "August 4, 2020",
      title: "ISS Acquires Asset Management Intelligence Provider Flowspring",
      url: "https://insights.issgovernance.com/posts/iss-market-intelligence-acquires-asset-management-intelligence-provider-flowspring/",
    },
    {
      publication: "Citywire",
      date: "July 3, 2019",
      title: "'Bad news for active managers': Researchers react to Morningstar's ratings overhaul",
      url: "https://citywireusa.com/registered-investment-advisor/news/bad-news-for-active-managers-researchers-react-to-morningstars-ratings-overhaul/a1246962",
    },
    {
      publication: "The New York Times",
      date: "April 5, 2019",
      title: "A Price War Has Driven Fund Fees to Zero. They May Be Set to Drop Further.",
      url: "https://www.nytimes.com/2019/04/05/business/price-war-fund-fees-zero-negative.html",
    },
    {
      publication: "Citywire Select",
      date: "March 18, 2019",
      title: "Why asset managers will soon pay you to invest in funds",
      url: "https://citywireselector.com/news/why-asset-managers-will-soon-pay-you-to-invest-in-funds/a1211046",
    },
    {
      publication: "Financial Times",
      date: "January 28, 2019",
      title: "Asset Managers Look to Cull Weak Funds",
      url: "https://www.ft.com/content/d31c05ca-d7be-3e4e-a73a-86a4490bdc62",
    },
    {
      publication: "Financial Times",
      date: "January 28, 2019",
      title: "JPMorgan Makes Late Push to Become ETF Heavyweight",
      url: "https://www.ft.com/content/267cd083-fd18-3556-ace3-3dc13380b144",
    },
    {
      publication: "Financial Times",
      date: "June 11, 2018",
      title: "Funds 'Snowball' Means Big Firms Can Only Get Bigger",
      url: "https://www.ft.com/content/1611bea8-68d3-11e8-b6eb-4acfcfb08c11",
    },
    {
      publication: "The New York Times",
      date: "May 4, 2018",
      title: "Who Runs Mutual Funds? Very Few Women",
      url: "https://www.nytimes.com/2018/05/04/your-money/who-runs-mutual-funds-very-few-women.html",
    },
    {
      publication: "Ignites",
      date: "April 24, 2018",
      title: "Defining the Sales Opportunity for ESG Funds",
      url: "https://distribution.ignites.com/c/1939934/227644?referrer_module=allresearch&highlight=",
    },
    {
      publication: "Financial Times",
      date: "March 30, 2018",
      title: "Morningstar Ratings Prompted $533bn of Fund Flows Last Year",
      url: "https://www.ft.com/content/27f196e6-334c-11e8-b5bf-23cb17fd1498",
    },
    {
      publication: "Financial Times",
      date: "February 2, 2018",
      title: "Mixed Gender Teams Lure Greater Investor Inflows",
      url: "https://www.ft.com/content/a1f973a0-1312-11e8-8cb6-b9ccc4c4dbbb",
    },
  ];

  const storyDivs = stories.map(s => (
    <div className="story">
      <a href={s.url} target="_blank" rel="noreferrer">
        <span className="title">{s.title}</span>
        <span className="publication">{` - ${s.publication}`}</span>
        <span className="pubDate">
          {`  (${s.date})`}
        </span>
      </a>
    </div>
  ));
  return (
    <Layout>
      <SEO
        title="News"
        ogImage={ogImage}
        keywords={[
          "Flowspring",
          "news",
          "newsworthy",
          "asset management",
          "competition",
        ]}
        description="See the most significant citations of Flowspring research in the most trusted media outlets"
      />
      <div id="news">
        <h1>Flowspring in the News</h1>
        {storyDivs}
      </div>
    </Layout>
  );
};

export default News;
